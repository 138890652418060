import { createMachine, interpret, invoke, transition } from 'robot3';
import { writable, zip } from 'svelte-pipeable-store';

export * from 'robot3';
export { state as final } from 'robot3';
export const nested = (to, states) => {
	const child = createMachine(states);
	return invoke(child, transition("done", to));
};

import * as rx from 'svelte-pipeable-store';

export { rx };

export const matchState = (val, ...matches) => matches.some(v => val === v);
export const debounce = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export const throwOnNull = (val) => {
	if (val === null) throw "Value was null";
}

//Shamelessly copied from rxjs lib
export const firstValueFrom = (source) => {
	return new Promise((resolve, reject) => {
		//Important: Cannot use a const, 'var' has to be here... weird javascript stuff
		//Important: Have to do this weird zip merge stuff so sub isn't null.. weird, just, stuff...
		var sub = zip([writable(undefined), source]).subscribe((value) => {
			if (value.length === 2) { //Length of two to ensure a value has been emitted by source
				resolve(value[1]);
				//TODO: Do I have to unsub? what happens if I don't??
				//sub.unsubscribe ? sub.unsubscribe() : sub();
			}
		});
	});
}

export const passObsToProm = async (observable, promise) => {
	return promise(await firstValueFrom(observable));
}

export const useMachine = (machineConfig, event = {}) => {
	const machine = createMachine(machineConfig);

	const service = interpret(machine, innerService => {
		if (service == innerService) {
			setState({ current: innerService.machine.current, child: innerService.child?.machine });
			setContext(innerService.context);
		} else {
			updateState(state => ({ current: state.current, child: innerService.machine }))
			setContext(innerService.context);
		}
	}, event);

	const { set: setState, update: updateState, ...state } = writable({ current: service.machine.current, child: service.child?.machine });
	const { set: setContext, update: updateContext, ...context } = writable(service.context);

	const createSender = (service) => new Proxy(service, {
		get(obj, prop) {
			if (prop === "child") {
				if (obj.child) return createSender(obj.child);
				return undefined;
			}
			return (val) => obj.send({ type: prop, data: val });
		}
	});

	return [state, context, createSender(service)];
};