export const clickOutside = (node, params) => {
  const isNestedChild = (parent, child) => {
    let currentNode = child;

    while (currentNode !== null) {
      if (currentNode.parentNode === parent) {
        return true;
      } else {
        currentNode = currentNode.parentNode;
      }
    }

    return false;
  };

  const handleClick = (event) => {
    const { target } = event;

    if (!node.isSameNode(target) && !isNestedChild(node, target) && !params.exclusions.includes(target.id)) {
      params.callback();
    }
  };

  document.addEventListener("click", handleClick);

  return {
    destroy() {
      document.removeEventListener("click", handleClick);
    },
  };
};

export const setUpper = (string) => {
  return string[0].toUpperCase() + string.substring(1);
};

export const stringSepToNorm = (string, sep = "-") => {
  return string
    .split(sep)
    .map((s) => setUpper(s))
    .join(" ");
};

export const flattenData = (data, keyParents = []) => {
  let keyParentsByKey = {};

  const _flatten = (data, keyParents = []) => {
    let flatData = {};
    
    for (const [key, val] of Object.entries(data)) {
      if (Array.isArray(val)) {
        flatData = { ...flatData };

        if (val.length == 1) {
          flatData = { ...flatData, ..._flatten(val[0], [...keyParents, key]) };
        } else {
          flatData[key] = val.map((d, i) => _flatten(d, [...keyParents, key, i]));
        }
      } else if (Object.prototype.toString.call(val) === "[object Object]") {
        flatData = { ...flatData, ..._flatten(val, [...keyParents, key]) };
      } else if (key != "__typename" && key != "__objectpath") {
        keyParentsByKey[key] = keyParents;
        flatData[key] = val;
      }
    }

    return flatData;
  };

  return { data: _flatten(data, []), keyParentsByKey };
};

export const trySet = async (callback, useInstead) => {
  let value;

  try {
      value = await callback();
  } catch (e) {
      value = useInstead;
  }

  return value;
}