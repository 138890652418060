import { state, transition, final, nested, invoke, reduce, action, guard, immediate, passObsToProm, firstValueFrom } from "../rxfsm";
import { systemContext as systemContextStore, systemExtendedContext as systemExtendedContextStore } from "../stores";
import { mutateClient } from "../GraphQL/mutate";
import { queryClient } from "../GraphQL/query";

import { tick } from "svelte";

const unsetContextsForUI = (uiConfig, reqSystemContextkeys) => {
  const requiredSysContextsForUI = uiConfig?.routes
    ?.find((route) => route.name == "profile")
    ?.tabroutes?.find((route) => route.name == "contexts")
    ?.body.filter((comp) => comp.item == "ContextSetter")
    .map((comp) => comp.options) ?? [];

  return requiredSysContextsForUI.filter(
    (context) => !reqSystemContextkeys.includes(context?.field)
  );
}

const newContextsForUnset = async (unsetContexts) => {
  const newContextObjects = await Promise.all(unsetContexts.map(async unsetContext => {
    if (unsetContext.options) {
      if (unsetContext.options[0]) {
        return { [unsetContext?.field]: unsetContext.options[0] };
      }
    } else {
      const { data: { data } } = await queryClient({ query: unsetContext.optionsQuery });
      if (data[0]) return { [unsetContext?.field]: data[0] };
    }
  }));

  return newContextObjects.reduce((obj, item) => ({ ...obj, ...item }), {});
}

const mutateNewLastContexts = async ({ systemContext, needMutateNewLastContexts }) => { //No point in await here, we don't care when its done
  if (needMutateNewLastContexts) {
    mutateClient({
      mutation: `mutation Mutation($new_contexts: jsonb!, $context_user_id: uuid!) {
        update_auth_users_by_pk(_set: {last_contexts: $new_contexts}, pk_columns: {id: $context_user_id}) {
          last_contexts
        }
      }`,
      variables: {
        new_contexts: systemContext,
      },
    });
  }
}

const querySystemContext = async ({ systemContext, systemExtendedContext }) => {
  if (systemContext) {
    if (systemContext.organization && !systemContext.system) { //Here with an organization set, but no system set
      const { data: { data: { 0: newSystem } } } = await queryClient({
        query: `query Query($new_organization_id: uuid!) {
          data: ui_systems(where: {organization: {_eq: $new_organization_id }}, limit: 1) { 
            label: name
            id  
          } 
        }`,
        variables: {
          new_organization_id: systemContext.organization.id
        }
      });

      return { organization: systemContext.organization, system: newSystem };
    } else if (systemExtendedContext) { //Here after 'identifyingui' happened, so need to get the unsetContextsForUI
      const unsetContexts = unsetContextsForUI(systemExtendedContext.ui, Object.keys(systemContext));
      const newContext = await newContextsForUnset(unsetContexts);

      return { ...systemContext, ...newContext };
    }
  } else {
    const cachedLastContexts = localStorage.getItem("systemContext");

    if (cachedLastContexts) {
      return JSON.parse(cachedLastContexts);
    } else {
      const { data: { data: { last_contexts } } } = await queryClient({
        query: `query Query($context_user_id: uuid!) {
          data: auth_users_by_pk(id: $context_user_id) {
            last_contexts
          }
        }`,
      });

      return last_contexts;
    }
  }
};

const querySystemExtendedContext = async ({ systemContext }) => {
  const cachedSystemJSON = localStorage.getItem("systemExtendedContext");
  const cachedSystemSysId = localStorage.getItem("systemExtendedContextSysId");

  if (cachedSystemJSON && systemContext.system.id == cachedSystemSysId) {
    return JSON.parse(cachedSystemJSON);
  } else {
    const { data: { system } } = await queryClient({
      query: `query Query($context_system_id: bigint!) { 
        system: ui_systems_by_pk(id: $context_system_id) { 
          ui logo theme support version server functions files
        } 
      }`
    });

    return system;
  }
};

const validateUIReady = ({ systemContext, systemExtendedContext }) => {
  const unsetContexts = unsetContextsForUI(systemExtendedContext.ui, Object.keys(systemContext));

  if (unsetContexts.length === 0) return true;
  return false;
}

const loggedinMachine = (getParentContextObs) => nested("signingout", {
  identifyingcontexts: invoke(() => tick().then(() => passObsToProm(getParentContextObs(), querySystemContext)),
    transition(
      "done",
      "identifyingui",
      reduce((ctx, ev) => ({ ...ctx, systemContext: ev.data })), //System ID and stuff
      action((ctx) => {
        const sc = ctx.systemContext;

        systemContextStore.set(sc);
        localStorage.setItem("systemContext", JSON.stringify(sc));
      }),
    ),
    transition(
      "error",
      "signout",
      reduce((ctx, ev) => {
        console.error(ev.error);
        return ({ ...ctx });
      }),
    ),
  ),
  identifyingui: invoke(() => tick().then(() => passObsToProm(getParentContextObs(), querySystemExtendedContext)),
    transition(
      "done",
      "validateuiready",
      reduce((ctx, ev) => ({ ...ctx, systemExtendedContext: ev.data })), //System UI, system theme, server url, functions url, files url, etc.
      action((ctx) => {
        const sec = ctx.systemExtendedContext;
        const sc = ctx.systemContext;

        systemExtendedContextStore.set(sec);
        localStorage.setItem("systemExtendedContext", JSON.stringify(sec));
        localStorage.setItem("systemExtendedContextSysId", sc.system.id);

        const theme = sec.theme;

        if (theme?.variables) {
          Object.entries(theme.variables).forEach(([key, val]) => {
            document.documentElement.style.setProperty(`--${key}`, val);
          });
        }
      }),
    ),
    transition(
      "error",
      "signout",
      reduce((ctx, ev) => {
        console.error(ev.error);
        return ({ ...ctx });
      }),
    ),
  ),
  validateuiready: state(
    immediate(
      "uiready",
      guard(validateUIReady),
      action(mutateNewLastContexts),
      reduce((ctx) => ({ ...ctx, needMutateNewLastContexts: false })),
    ),
    immediate("identifyingcontexts"),
  ),
  uiready: state(
    transition(
      "changecontexts",
      "identifyingcontexts",
      reduce((ctx, ev) => (console.log("EY"), { ...ctx, systemContext: ev.data, needMutateNewLastContexts: true })),
      action((ctx) => {
        localStorage.removeItem("systemExtendedContext");
        localStorage.removeItem("systemExtendedContextSysId");
      }),
    ),
    transition("signout", "signout"),
  ),
  signout: final(),
});

export default loggedinMachine;