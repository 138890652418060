<script>
  import FormField from "../FormField.svelte";
  export let value = {};
  export let taskTypes = {};

  $: if (!value) value = {};

  $: if (value?.label) {
    value.field = value.label.toLowerCase().split(" ").join("-");
  }
</script>

<div class="space-y-2">
  <FormField
    bind:value={value['type']}
    fieldData={{ field: 'type', label: 'Type', placeholder: 'Type', width: 'full', type: 'select', options: Object.keys(taskTypes) }}
    hideLabel />
  <FormField
    bind:value={value['label']}
    fieldData={{ field: 'label', label: 'Label', placeholder: 'Label', width: 'full', type: 'text' }}
    hideLabel />
  {#each taskTypes[value['type']]?.extraFields ?? [] as extraField}
    <FormField
      bind:value={value[extraField.field]}
      fieldData={{ ...extraField, width: 'full', placeholder: extraField.label }}
      hideLabel />
  {/each}
</div>
