<script type="ts">
  import { notificationOpts } from "../stores";
  import { onMount } from "svelte";

  import Yace from "yace";
  import Prism from "prismjs";
  import tab from "yace/dist/plugins/tab.js";
  import history from "yace/dist/plugins/history.js";
  import cutLine from "yace/dist/plugins/cutLine.js";
  import preserveIndent from "yace/dist/plugins/preserveIndent.js";

  import { QueryStore } from "../GraphQL/query";
  import { mutateClient } from "../GraphQL/mutate";

  export let options;

  let editor;

  const plugins = [
    history(), // suuport ctrl+z ctrl+shift+z when use plugins. should be first
    tab(), // indent with two space
    cutLine(), // cmd + x for cutting line
    preserveIndent(), // preserve last line indent
  ];

  const format = (useValue = editor?.value) => {
    let hadError = false;

    try {
      const parsedJson = JSON.parse(useValue);
      editor.update({ value: JSON.stringify(parsedJson, undefined, 2) });
    } catch (e) {
      hadError = true;

      if (e.name == "SyntaxError") {
        const errorSpot = parseInt(e.message.replace(/Unexpected (.*) in JSON at position /g, ""));

        editor.textarea.focus();
        editor.update({ selectionStart: errorSpot, selectionEnd: errorSpot + 1 });

        notificationOpts.set({
          title: "JSON Error Highlighted",
          color: "red",
          icon: "info",
          timeout: 5000,
        });
      } else {
        throw e;
      }
    }

    return { hadError };
  };
  const save = async () => {
    const { hadError } = format();
    if (!hadError) {
      await mutateClient({
        mutation: options.mutation,
        variables: {
          editor_value: JSON.parse(editor.value),
        },
      });

      notificationOpts.set({
        title: "Saved Value",
        color: "green",
        icon: "info",
        timeout: 5000,
      });
    }
  };

  console.log(options.query);
  const queryStore = new QueryStore({ query: options?.query });
  const queryDataStore = queryStore.getDataStore();

  $: if (editor && $queryDataStore?.data?.data) editor.update({ value: JSON.stringify($queryDataStore["data"]["data"]["data"], undefined, 2) });

  function handleKeydown(event) {
    if (event.key === "s" && event.ctrlKey === true) {
      event.preventDefault();
      
      save();
    }
  }

  onMount(() => {
    editor = new Yace("#editor", {
      value: JSON.stringify($queryDataStore, undefined, 2),
      styles: {
        fontSize: "18px",
        "min-width": "100%",
      },
      highlighter: (v) => Prism.highlight(v, Prism.languages.javascript, "javascript"),
      plugins,
      lineNumbers: true,
    });

    editor.textarea.spellcheck = false;
  });
</script>

<style global>
  code[class*="language-"],
  pre[class*="language-"] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;

    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;

    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  pre[class*="language-"]::-moz-selection,
  pre[class*="language-"] ::-moz-selection,
  code[class*="language-"]::-moz-selection,
  code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  pre[class*="language-"]::selection,
  pre[class*="language-"] ::selection,
  code[class*="language-"]::selection,
  code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  @media print {
    code[class*="language-"],
    pre[class*="language-"] {
      text-shadow: none;
    }
  }

  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #f5f2f0;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: slatesecondary;
  }

  .token.punctuation {
    color: #999;
  }

  .token.namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #905;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #690;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #9a6e3a;
    /* This background color was intended by the author of this theme. */
    background: hsla(0, 0%, 100%, 0.5);
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #07a;
  }

  .token.function,
  .token.class-name {
    color: #dd4a68;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #e90;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  .jsoneditor button {
    @apply relative inline-flex items-center px-4 py-2 border border-secondary-300 bg-white  text-sm leading-5 font-medium text-secondary-700 transition ease-in-out duration-150;
  }

  :global(.dark .jsoneditor button) {
    @apply bg-gray-800;
  }

  .jsoneditor button:hover {
    @apply text-secondary-500;
  }

  .jsoneditor button:focus {
    @apply z-10 outline-none border-blue-300 shadow-outline-blue;
  }

  .jsoneditor button:active {
    @apply bg-secondary-100 text-secondary-700;
  }

  .jsoneditor .editor-container {
    @apply w-full h-full pl-6;
    position: relative;
    border: none;
    line-height: 1.5;
  }
</style>

<svelte:window on:keydown={handleKeydown} />

<div class="jsoneditor rounded-lg shadow px-5 py-6 sm:px-6 mb-5" style="background: #f7f7f7;">
  <div class="rounded-lg flex flex-row" style="min-height: 55vh">
    <div class="flex flex-col w-1/4">
      <span class="relative z-0 inline-flex shadow-sm rounded-md mr-auto">
        <button type="button" on:click={() => format()} class="rounded-l-md"> Format </button>

        <button type="button" on:click={save} class="-ml-px rounded-r-md"> Save </button>
      </span>
    </div>
    <div class="editor-container w-3/4">
      <div id="editor" />
    </div>
  </div>
</div>
