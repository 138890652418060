function provider(node, [name, object]) {
	node.setAttribute(`data-provider-${name}`, "");
	node.providerData = object;

	return {
		update(newObject) {
      node.providerData = object;
		},
		destroy() {
      node.removeAttribute(`data-provider-${name}`);
			delete node.providerData;
		}
	};
}

export const Provider = new Proxy(provider, {
  get(obj, prop) {
    if (prop === "of") return (providerName, el) => el.closest(`*[data-provider-${providerName}]`).providerData;
    return obj[prop];
  }	
})