<script lang="ts">
  import { setUpper } from "@src/random";
  import { modalOpts } from "@src/stores";

  import JsonTaskEditor from "./JsonTasks/JsonTaskEditor.svelte";

  export let id: string,
    value: Array<any>,
    fieldData: any,
    required: boolean,
    disabled: boolean;
  export let roundRightonFocus = true;
  export let isFocused = false;

  const deleteTask = (index) => {
    let newValue = [...value];
    newValue.splice(index, 1);
    value = newValue;
  };

  const editTask = (value, index) => {
    modalOpts.set({
      title: `Edit Task`,
      description: "Edit the type you want for this task then set a label",
      color: "primary",
      exclusions: ["buttonedittask", "buttonedittask-p1", "buttonedittask-p2"],
      body: JsonTaskEditor,
      bodyOptions: { taskTypes: fieldData["taskTypes"], value },
      action: {
        name: "Save",
        callback: async (bodyValue) => {
          let newValue = [...value];
          newValue[index] = bodyValue;
          value = newValue;
        },
      },
    });
  };

  const addTask = () => {    
    modalOpts.set({
      title: `Add Task`,
      description: "Select the type you want for this task then set a label",
      color: "primary",
      exclusions: ["buttonaddtask"],
      body: JsonTaskEditor,
      bodyOptions: { taskTypes: fieldData["taskTypes"]},
      action: {
        name: "Add",
        callback: async (bodyValue) => {
          value = [...(value ?? []), bodyValue];
        },
      },
    });
  };
</script>

<div class="relative">
  <div
    class="relative {roundRightonFocus || !isFocused ? 'rounded-md' : 'rounded-l-md'} shadow-sm">
    <div
      class="form-input  {!!disabled ? 'bg-gray-200' : ''} {roundRightonFocus || !isFocused ? '' : 'rounded-r-none'} block w-full text-left sm:text-sm sm:leading-5 transition ease-in-out duration-150">
      {#if !disabled}
        <button
          type="button"
          id="buttonaddtask"
          on:click={addTask}
          class="w-full items-center px-3 py-2 text-sm leading-4 font-medium rounded-md text-white bg-secondary-600 hover:bg-secondary-500 focus:outline-none shadow overflow-hidden mb-3">
          Add Task
        </button>
      {/if}

      <div class="bg-white dark:bg-gray-800  shadow overflow-hidden rounded-md">
        <ul>
          {#key value}
            {#each value ?? [] as { type, field, label, options, value, ...other }, index}
              <li class="block px-4 py-4">
                <div class="flex items-center justify-between">
                  <div class="text-sm leading-5 font-medium truncate">
                    {label}
                  </div>
                  <div class="ml-2 flex-shrink-0 flex space-x-3">
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-primary-100 text-gray-800 dark:text-gray-200">{setUpper(type)}</span>
                    {#if !disabled}
                      <svg
                        id="buttonedittask"
                        on:click={() => editTask({ type, field, label, ...other }, index)}
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          id="buttonedittask-p1"
                          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path
                          id="buttonedittask-p2"
                          fill-rule="evenodd"
                          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                          clip-rule="evenodd" />
                      </svg>
                      <svg
                        id="buttondeletetask"
                        on:click={() => deleteTask(index)}
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 hover:text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor">
                        <path
                          id="buttondeletetask-p1"
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                      </svg>
                    {/if}
                  </div>
                </div>
                {#if options || value}
                  <div class="mt-2">
                    {#if value}
                      <div
                        class="mr-6 flex items-center text-sm leading-5 text-gray-500 dark:text-gray-200">
                        Value:
                        {value}
                      </div>
                    {/if}
                    {#if options}
                      {#each Object.entries(options) as [k, v]}
                        <div
                          class="mr-6 flex items-center text-sm leading-5 text-gray-500 dark:text-gray-200">
                          {k}:
                          {v}
                        </div>
                      {/each}
                    {/if}
                  </div>
                {/if}
              </li>
            {/each}
          {/key}
        </ul>
      </div>
    </div>
  </div>
</div>
