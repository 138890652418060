<script lang="ts">
  import DateTimepicker from "./DateTimepicker.svelte";

  export let id: string, value: Array<number>, required: boolean, disabled: boolean;
  export let isUnsavedUpdates = false;
  export let roundRightonFocus = true;
  export let closeOnSelect = false;

  let start;
  let end;

  $: value = [start, end];
</script>

<div class="flex flex-row">
  <DateTimepicker passClass="w-full" inputPassClass="rounded-r-none border-r-0" bind:value={start} {id} {roundRightonFocus} {closeOnSelect} required />
  <DateTimepicker passClass="w-full" inputPassClass="rounded-l-none" bind:value={end} {id} {roundRightonFocus} {closeOnSelect} required />
</div>
