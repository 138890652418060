import { notificationOpts, modalOpts } from "./stores";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (globalThis.__ENV__.__IS_PROD__) {
  window.console.log = function no_console() {};
  Sentry.init({
    dsn: "https://e4e3b700e728400b85015b2c486b5c0b@o478554.ingest.sentry.io/5521231",
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
      if (event.exception) Sentry.showReportDialog({ eventId: event.event_id });
      return event;
    },
    tracesSampleRate: 1.0,
  });
}

export const handleError = (error) => {
  if (error.reason.name == "GQLMutationError") {
    notificationOpts.set({
      title: error.reason.message,
      color: "red",
      icon: "info",
      timeout: 5000,
    });
  } else {
    if (globalThis.__ENV__.__IS_PROD__) {
      Sentry.captureException(new Error(error?.reason), {});
    } else {
      modalOpts.set({
        title: "There has been an issue.",
        description: error?.reason,
        color: "red",
        icon: "warn",
        hideCancel: true,
        action: {
          name: "OK",
          callback: () => {},
        },
      });
    }
  }
};