<script lang="ts">
  import { stringSepToNorm, flattenData } from "../random";
  import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter(val => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"))

  import { QueryStore } from "../GraphQL/query";
  import { getQueryFirstSelectionName, getQueryFirstSelection, getSourceSelections, getQueryVariableType } from "../GraphQL/helpers";

  import GeneralHeading from "../General/GeneralHeading.svelte";

  import FormField from "./Form/FormField.svelte";
  import TableSortButton from "./Table/TableSortButton.svelte";
  import tableActions from "./Table/tableActions";
  import Loader from "../General/Loader.svelte";
  export let options;

  const tableName = getQueryFirstSelectionName(options?.query);

  let sortStates: { [index: string]: "asc" | "desc" | null } = options.defaultOrderBy ?? {};
  let notActuallyUsedWhereValues: { [index: string]: string } = {};
  let whereValues: { [index: string]: string } = {};

  let tableKeyParentsByKey;
  let tableData: Array<any> = [];
  let tableKeys: Array<string> = [];

  let selectedRow;

  const queryStore = new QueryStore({
    query: options?.query,
    variables: { order_by: {} },
    paginate: true,
    insertQueryVariables: { order_by: `[${tableName}_order_by!]` },
  });
  const queryVariablesStore = queryStore.variablesStore;
  const queryDataStore = queryStore.getDataStore();

  const getOrderBy = () =>
    !tableKeyParentsByKey
      ? {}
      : Object.fromEntries(
          Object.entries(sortStates)
            .filter(([k, v]) => v != null)
            .map(([k, v]) => {
              return tableKeyParentsByKey[k].length == 0
                ? [findAliasName(k) ?? k, v]
                : [
                    //Sorry to anyone who has to debug this function
                    tableKeyParentsByKey[k][0],
                    [...tableKeyParentsByKey[k].slice(1), findAliasName(k) ?? k, v].reduceRight((a, cv) => {
                      let n = {};
                      n[cv] = a;
                      return n;
                    }),
                  ];
            }),
        );
  const getWheres = () =>
    Object.fromEntries(
      Object.entries(whereValues)
        .filter(([k, v]) => v != undefined)
        .map(([k, v]) =>
          tableKeyParentsByKey[k].length == 0
            ? [findAliasName(k) ?? k, findWhereValue(k, v)]
            : [
                //Sorry to anyone who has to debug this function
                tableKeyParentsByKey[k][0],
                [...tableKeyParentsByKey[k].slice(1), findAliasName(k) ?? k, findWhereValue(k, v)].reduceRight((a, cv) => {
                  let n = {};
                  n[cv] = a;
                  return n;
                }),
              ],
        )
        .map(([k, v]) => [`where_${k}`, v]),
    );

  const getAvailableActions = () => (options?.actions as Array<any>)?.map(getTableAction) ?? [];
  const getTableAction = (action) => {
    const key = typeof action == "string" ? action : action["key"];
    return { ...tableActions[key](action), key: key };
  };

  $: console.log(selectedRow);
  $: if ($queryDataStore?.data?.data) {
    tableData = ($queryDataStore["data"]["data"] as Array<any>).map((en) => {
      const { data, keyParentsByKey } = flattenData(en);

      tableKeyParentsByKey = keyParentsByKey;

      return data;
    });

    selectedRow = null;
  }

  var delayTimer;
  const timeStuffsIdk = () => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      whereValues = { ...notActuallyUsedWhereValues };
    }, 500);
  };

  $: timeStuffsIdk(), notActuallyUsedWhereValues;

  const removeKeys = (obj: any, pattern: RegExp) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => !pattern.test(k)));

  $: if (tableData[0]) tableKeys = Object.keys(tableData[0]).filter((key) => !(options.queryHidden ?? []).includes(key));
  $: if (Object.keys(sortStates).length > 0) {
    ($queryVariablesStore = { ...$queryVariablesStore, order_by: getOrderBy() }), sortStates;
  }
  $: if (Object.keys(whereValues).length > 0) {
    ($queryVariablesStore = { ...removeKeys($queryVariablesStore, /where_(.*?)/), ...getWheres() }), whereValues;
  }

  const findAliasName = (alias: string, source = getQueryFirstSelection(options.query)): string => {
    if (options?.customAliasNames && alias && options.customAliasNames[alias]) return options.customAliasNames[alias].name;

    const selections = getSourceSelections(source);
    let found;

    for (const sel of selections) {
      if (sel.alias?.value == alias) found = sel["name"]["value"];
      if (sel.selectionSet) {
        const subSearch = findAliasName(alias, sel);

        if (subSearch) found = subSearch;
      }

      if (found) break;
    }

    return found;
  };

  const findWhereValue = (k: string, v: string): any => {
    const queryVarType = getQueryVariableType(options.query, `where_${findAliasName(k) ?? k}`);

    if (queryVarType?.includes("_enum_comparison_exp")) {
      return { _eq: v };
    }

    switch (queryVarType) {
      case "String_comparison_exp":
        if (v === "") return {};
        return { _ilike: `%${v}%` };
      case "uuid_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "Int_comparison_exp":
        return {
          _gte: v[0],
          _lte: v[1],
        };
      case "timestamptz_comparison_exp":
        return {
          _gte: v[0],
          _lte: v[1],
        };
      case "numeric_comparison_exp":
        return {
          _gte: v[0],
          _lte: v[1],
        };
      case "bigint_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "date_comparison_exp":
        return {
          _gte: v[0],
          _lte: v[1],
        };
      case "Boolean_comparison_exp":
        return { _eq: v };
      case "timestamp_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "jsonb_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
    }
  };

  const getPaginationIndexes = (len: number, currentPage: number): string[] => {
    const array: string[] = [...Array(len + 1)].map((_, i) => i.toString()).slice(1);

    if (array.length <= 5) return array;

    const firstPage = currentPage === 0 ? [] : ["1", ...(currentPage !== 1 ? ["..."] : [])]
    const lastPage = array.length-currentPage <= 2 ? [] : [...(currentPage !== array.length - 3 ? ["..."] : []), ...(currentPage === 0 ? [(array.length-1).toString()] : []), (array.length).toString()];

    return [...firstPage, ...array.slice(currentPage, currentPage+2), ...lastPage];
  }

  const findFilterOptions = (k: string): any => {
    const queryVarType = getQueryVariableType(options.query, `where_${findAliasName(k) ?? k}`);

    if (queryVarType?.includes("_enum_comparison_exp")) {
      return { type: "select", optionsQuery: options["varOptions"][`where_${findAliasName(k) ?? k}`]["optionsQuery"] };
    }

    switch (queryVarType) {
      case "String_comparison_exp":
        return { type: "text" };
      case "uuid_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "Int_comparison_exp":
        return { type: "numberrange" };
      case "timestamptz_comparison_exp":
        return { type: "datetimerange" };
      case "numeric_comparison_exp":
        return { type: "decimalrange" };
      case "bigint_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "date_comparison_exp":
        return { type: "daterange" };
      case "Boolean_comparison_exp":
        return { type: "select", options: [false, true] };
      case "timestamp_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      case "jsonb_comparison_exp":
        throw "Implement comparison type of " + queryVarType;
      default:
        return { type: "text", placeholder: "Missing Filter Type" };
    }
  };
</script>

<style>
  :global(.paginationButton) {
    @apply relative inline-flex items-center px-1 py-1 border-b border-t border-gray-300 bg-white
                    text-xs leading-4 font-medium text-gray-500 transition
                    ease-in-out duration-150;
  }

  :global(.dark .paginationButton) {
    @apply bg-gray-800 text-gray-200;
  }

  :global(.paginationButton.active) {
    @apply bg-secondary-200;
  }

  :global(.dark .paginationButton.active) {
    @apply bg-secondary-700;
  }

  .paginationButton + .paginationButton {
    @apply border-l;
  }

  .paginationButton:first-child {
    @apply border-l rounded-l-md;
  }

  .paginationButton:last-child {
    @apply border-r rounded-r-md;
  }

  .paginationButton:hover {
    @apply text-gray-400;
  }

  .paginationButton:focus {
    @apply z-10 outline-none border-blue-300 shadow-outline-blue;
  }

  :global(.paginationButton:active) {
    @apply bg-white  text-gray-500;
  }

  :global(.dark .paginationButton:active) {
    @apply bg-gray-800 text-gray-200;
  }

  .clickableAction {
    @apply opacity-50 cursor-not-allowed;
  }

  :global(th) {
    @apply px-3 border-b border-gray-200 bg-white  text-left leading-3 font-bold text-gray-500 tracking-wider;
  }

  :global(.dark th) {
    @apply bg-gray-800 text-gray-200 border-gray-700;
  }

  th + th {
    @apply border-l;
  }

  :global(.dark td + td) {
    @apply border-gray-700;
  }

  td {
    @apply px-3 leading-4 truncate overflow-x-hidden;
  }

  td:not(.bottom) + td:not(.bottom) {
    @apply border-l;
  }

  :global(tr) {
    @apply bg-white  text-gray-900 border-b border-gray-200;
  }

  :global(.dark tr) {
    @apply bg-gray-800  text-gray-200 border-b border-gray-700;
  }

  :global(tfoot tr td) {
    @apply px-3 py-2 text-left text-xs leading-3 font-bold text-gray-500 tracking-wider content-end items-end;
  }

  :global(.dark tfoot tr td) {
    @apply text-gray-200;
  }

  :global(.selectedRow) {
    @apply bg-primary-200;
  }

  :global(.dark .selectedRow) {
    @apply bg-primary-800;
  }
</style>

<div class="overflow-x-auto">
  <div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-400 dark:border-gray-900">
    {#key options?.actions}
      <GeneralHeading title={stringSepToNorm(tableName, '_')}>
        {#each getAvailableActions() as action}
          {#if action['roles'] ? auth.helper.checkRoles($userRoles, action['roles']) : true}
            <button
              type="button"
              id={tableName + '-button' + action['key']}
              class="actionButton"
              class:clickableAction={action.needSelectedRow ? !selectedRow : false}
              on:click={() => action.action(tableName, selectedRow, () => {
                  selectedRow = null;
                  queryStore.doQuery();
                })}
              disabled={action.needSelectedRow ? !selectedRow : false}>
              {action?.label}
            </button>
          {/if}
        {/each}
      </GeneralHeading>
    {/key}

    {#if $queryDataStore?.loading}
      <Loader />
    {:else if !tableKeys}
      <GeneralHeading title="No data found" />
    {:else}
      <table class="min-w-full m-0">
        <thead class="m-0">
          <tr>
            {#each tableKeys as key}
              <th class="py-2 text-xs uppercase">
                <div class="flex flex-row">
                  <label class="flex" for={tableName + '-' + key}>
                    <span class="my-auto">{stringSepToNorm(key, '_')}</span>
                  </label>
                  <TableSortButton bind:state={sortStates[key]} />
                </div>
              </th>
            {/each}
          </tr>
          <tr>
            {#each tableKeys as key}
              <th class="py-2">
                <FormField
                  bind:value={notActuallyUsedWhereValues[key]}
                  fieldData={{ field: key, label: key, placeholder: 'Search', width: 'full', ...findFilterOptions(key) }}
                  hideLabel />
              </th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#if tableData}
            {#each tableData as dataEntry}
              <tr on:click={() => (selectedRow == dataEntry ? (selectedRow = null) : (selectedRow = dataEntry))} class:selectedRow={selectedRow == dataEntry}>
                {#each tableKeys as key, keyIndex}
                  {#if Array.isArray(dataEntry[key])}
                    <td class="py-2 text-xs">{dataEntry[key].map((en) => Object.values(en).join('-')).join(', ')}</td>
                  {:else}
                    <td class="py-2 text-xs">{dataEntry[key] != null ? dataEntry[key] : ''}</td>
                  {/if}
                {/each}
              </tr>
            {/each}
            {#each Array(12 - tableData.length) as _}
              <tr>
                {#each tableKeys as key, keyIndex}
                  <td class="py-2 text-xs text-white">
                    <span class="dark:text-gray-800">.</span>
                  </td>
                {/each}
              </tr>
            {/each}
          {:else}
            <tr>
              {#each tableKeys as key, keyIndex}
                <td class="py-2 text-xs">
                  {#if keyIndex == 0}No data found{/if}
                </td>
              {/each}
            </tr>
          {/if}
        </tbody>
        <tfoot>
          {#key tableData}
            <tr class="bg-white dark:bg-gray-800 ">
              <td class="bottom font-semibold">
                Showing
                {queryStore.currentOffset}
                to
                {queryStore.currentOffset + queryStore.offsetPageLength > queryStore.totalCount ? queryStore.totalCount : queryStore.currentOffset + queryStore.offsetPageLength}
                of
                {queryStore.totalCount}.
              </td>
              {#each tableKeys as key, keyIndex}
                {#if keyIndex == tableKeys.length - 1}
                  <td class="flex bottom">
                    <nav class="ml-auto self-end z-0 inline-flex shadow-sm rounded-md">
                      <button on:click={() => queryStore.lastPage()} class="paginationButton" aria-label="Previous">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0
                      010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd" />
                        </svg>
                      </button>

                      {#each getPaginationIndexes(queryStore.pageCount, queryStore.currentPage) as index}
                        <button disabled={isNaN(parseInt(index))} on:click={() => queryStore.changePage(parseInt(index) - 1)} class="paginationButton {parseInt(index) == (queryStore.currentPage+1) ? 'active' : ''}">
                          {index}
                        </button>
                      {/each}

                      <button on:click={() => queryStore.nextPage()} class="paginationButton" aria-label="Next">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010
                      1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd" />
                        </svg>
                      </button>
                    </nav>
                  </td>
                {:else if keyIndex != 0}
                  <td class="bottom" />
                {/if}
              {/each}
            </tr>
          {/key}
        </tfoot>
      </table>
    {/if}
  </div>
</div>
