<script lang="ts">
  export let id: string, value: boolean = false, required: boolean, disabled: boolean;
  export let isUnsavedUpdates = false;
  export let roundRightonFocus = true;
  export let isFocused = false;
  $: if(value == null || value == undefined) value = false;
</script>

<style>
  .toggleBtnWrapper {
    @apply block w-full h-full;
    padding-top: 0.285rem;
    padding-bottom: 0.285rem;
  }
  .toggleBtn {
    @apply relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200;
  }
  .toggle {
    @apply absolute inset-0 h-full w-full flex items-center justify-center transition-opacity;
  }
  .show {
    @apply opacity-100 ease-in duration-200;
  }

  .hide {
    @apply opacity-0 ease-out duration-100;
  }
</style>

<div
  class="toggleBtnWrapper form-input {!isUnsavedUpdates ? 'bg-transparent border-0 px-0' : ''} {!!disabled ? 'bg-gray-200' : ''} {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''}">
  <button
    type="button"
    on:click={() => (value = !value)}
    aria-pressed="{!!value}"
    class="{value ? 'bg-secondary-600' : 'bg-gray-200'} toggleBtn ml-auto focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500">
    <span class="sr-only">Use setting</span>
    <span
      class="{value ? 'translate-x-5' : 'translate-x-0'} relative inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800  shadow transform ring-0 transition ease-in-out duration-200">
      <span class:show={!value} class:hide={!!value} class="toggle" aria-hidden="true">
        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class:show={!!value} class:hide={!value} class="toggle" aria-hidden="true">
        <svg class="h-3 w-3 text-secondary-600" fill="currentColor" viewBox="0 0 12 12">
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
        </svg>
      </span>
    </span>
  </button>
</div>
