import { writable, get } from "svelte/store";
import { getQueryVariableNames, addQueryVariables, getVariablesFromContext, getQueryFirstSelection, getQueryFirstSelectionName } from "./helpers";
import { client } from "./client";
import combineQuery from "graphql-combine-query";
import gql from "graphql-tag";
const paginateQuery = (query, variables = {}) => {
    const queryVariables = JSON.parse(JSON.stringify(getQueryFirstSelection(query)["arguments"]));
    const queryName = getQueryFirstSelectionName(query);
    const mainQuery = addQueryVariables(gql(query), { limit: "Int", offset: "Int" });
    const aggregateQuery = gql `
        query AggregateQuery {
            aggregate: ${queryName}_aggregate { 
                aggregate { count } 
            } 
        }
    `;
    aggregateQuery["definitions"][0]["selectionSet"]["selections"][0]["arguments"] = queryVariables;
    const { document } = combineQuery("Query").add(mainQuery, variables).add(aggregateQuery, {});
    return document;
};
const queryClient = async ({ query, paginate = false, variables = {}, insertQueryVariables = {} }) => {
    let finalQuery = (() => {
        if (paginate)
            return paginateQuery(query, variables);
        return gql(query);
    })();
    finalQuery = addQueryVariables(finalQuery, insertQueryVariables);
    const queryVariableNames = getQueryVariableNames(finalQuery);
    const finalVariables = { ...variables, ...(await getVariablesFromContext(queryVariableNames)) };
    return client.query({
        query: finalQuery,
        variables: finalVariables,
    });
};
class QueryStore {
    constructor({ query, variables = {}, paginate = false, offsetPageLength = 12, insertQueryVariables = {} }) {
        this.currentOffsetAmount = 0;
        this.currentOffsetPage = 0;
        this.offsetPageLength = offsetPageLength;
        this.dataStore = writable({ loading: true });
        let extraVariables = () => ({});
        if (paginate) {
            extraVariables = () => ({
                offset: this.currentOffsetAmount,
                limit: this.offsetPageLength,
            });
        }
        this.doQuery = () => {
            let variables = { ...extraVariables(), ...get(this.variablesStore) };
            queryClient({ query, paginate, variables, insertQueryVariables }).then(({ data }) => {
                var _a, _b;
                this.dataCount = (_b = (_a = data === null || data === void 0 ? void 0 : data.aggregate) === null || _a === void 0 ? void 0 : _a.aggregate) === null || _b === void 0 ? void 0 : _b.count;
                let newPageCount = this.totalCount / this.offsetPageLength;
                newPageCount = newPageCount < 1 ? 1 : Math.ceil(newPageCount);
                this.offsetPageCount = newPageCount;
                this.dataStore.set({ data });
            });
        };
        this.variablesStore = writable(variables);
        if (paginate) {
            this.variablesStore.subscribe(() => this.changePage(0));
        }
        else {
            this.variablesStore.subscribe(this.doQuery);
        }
        //It would probably be nice to have this this setting variables again whenever context updates
        /*if (queryVariableNames.find((vn) => vn.substring(0, 8) == "context_")) {
          systemContext.subscribe(setFromContext);
          routeContext.subscribe(setFromContext);
        }*/
    }
    setVariableStore(value) {
        this.variablesStore.set(value);
    }
    get currentOffset() {
        return this.currentOffsetAmount;
    }
    get currentPage() {
        return this.currentOffsetPage;
    }
    get pageCount() {
        return this.offsetPageCount;
    }
    get totalCount() {
        return this.dataCount;
    }
    getDataStore() {
        return { subscribe: this.dataStore.subscribe };
    }
    changePage(page) {
        //this.dataStore.set({ loading: true });
        this.currentOffsetPage = page;
        this.currentOffsetAmount = this.currentOffsetPage * this.offsetPageLength;
        this.doQuery();
    }
    nextPage() {
        if (this.currentOffsetPage == this.offsetPageCount - 1)
            return;
        this.changePage(this.currentOffsetPage + 1);
    }
    lastPage() {
        if (this.currentOffsetPage == 0)
            return;
        this.changePage(this.currentOffsetPage - 1);
    }
}
export { QueryStore, queryClient };
