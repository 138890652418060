<script lang="ts">
  export let small = true;
  export let size = 6;
  export let icon;
</script>

<svg class="h-{size} w-{size}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  {#if small}
    {#if icon == 'user'}
      <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
    {:else if icon == 'building'}
      <path
        fill-rule="evenodd"
        d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
        clip-rule="evenodd" />
    {:else if icon == 'star'}
        <path 
          fill-rule="evenodd"
          clip-rule="evenodd" 
          d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
    {/if}
  {/if}
</svg>
