<script lang="ts">
  import { isDecimal } from "./helpers";


  export let id: string, value: Array<number>, required: boolean, disabled: boolean;
  export let isUnsavedUpdates = false;
  export let roundRightonFocus = true;

  let start;
  let end;

  $: value = [start, end];
</script>

<div class="flex flex-row">
  <input
    bind:value={start}
    on:keypress={isDecimal}
    on:paste={isDecimal}
    {id}
    {disabled}
    placeholder="Start"
    {required}
    type="decimal"
    class="number-input form-input block w-1/2 h-full {disabled ? 'bg-gray-200' : ''}
    rounded-r-none border-r-0 sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
  <input
    bind:value={end}
    on:keypress={isDecimal}
    on:paste={isDecimal}
    {id}
    {disabled}
    placeholder="End"
    {required}
    type="decimal"
    class="number-input form-input block w-1/2 h-full {disabled ? 'bg-gray-200' : ''}
    rounded-l-none {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
</div>
