export const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const pad = (value, padStart = 1) => (value < 10 ? value.toString().padStart(padStart, "0") : value.toString());
export const createOffset = (date) => {
    const sign = date.getTimezoneOffset() > 0 ? "-" : "+";
    const offset = Math.abs(date.getTimezoneOffset());
    const hours = pad(Math.floor(offset / 60), 2);
    const minutes = pad(offset % 60, 2);
    return sign + hours + ":" + minutes;
};
export const nth = (d) => {
    if (d > 3 && d < 21)
        return "th";
    switch (d % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};
export const formatAMPM = (date) => {
    var _a;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const strTime = (_a = hours % 12) !== null && _a !== void 0 ? _a : 12 + ":" + pad(minutes) + " " + ampm;
    return strTime;
};
export const adjustForTimezone = (date) => {
    const newDate = new Date(date.toISOString());
    const now = new Date();
    const timeOffsetInMS = now.getTimezoneOffset() * 60000;
    newDate.setTime(newDate.getTime() - timeOffsetInMS);
    return newDate;
};
export const undoAdjustForTimezone = (date) => {
    const newDate = new Date(date);
    const now = new Date();
    const timeOffsetInMS = now.getTimezoneOffset() * 60000;
    newDate.setTime(newDate.getTime() + timeOffsetInMS);
    return newDate;
};
export const areDatesSameDay = (date1, date2) => {
    const date1WithoutTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2WithoutTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return date1WithoutTime.toDateString() === date2WithoutTime.toDateString() ? true : false;
};
export const isDecimal = (evt) => {
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
    }
    else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault)
            theEvent.preventDefault();
    }
};
export const isNumber = (evt) => {
    var theEvent = evt || window.event;
    // Handle paste
    if (theEvent.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
    }
    else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|-/;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault)
            theEvent.preventDefault();
    }
};
