<script lang="ts">
  import { clickOutside } from "@src/random";
  import { fade } from "svelte/transition";

  export let id: string, value: string, required: boolean;
  export let roundRightonFocus = true;
  export let showTimepicker = false;

  $: value = value
    .split(":")
    .map((s) => s.padStart(2, "0"))
    .join(":");
  $: hours = value.split(":")[0];
  $: minutes = value.split(":")[1];

  const toggleTimepicker = () => {
    if (value == "") {
      const now = new Date();
      value = `${now.getHours()}:${now.getMinutes()}:00`;
    }

    showTimepicker = !showTimepicker;
  };
</script>

<style>
  :global(.datepicker) {
    border: 1px solid #aaa;
  }
</style>

<div class="relative">
  <div class="relative {roundRightonFocus || !showTimepicker ? 'rounded-md' : 'rounded-l-md'} shadow-sm">
    <input
      bind:value
      type="text"
      on:keydown|preventDefault
      on:paste|preventDefault
      {id}
      {required}
      on:click={toggleTimepicker}
      class="form-input dark:bg-gray-800 {roundRightonFocus || !showTimepicker ? '' : 'rounded-r-none'} block w-full text-left sm:text-sm
        sm:leading-5 transition ease-in-out duration-150" />
  </div>
  {#if showTimepicker}
    <div
      use:clickOutside={{ callback: toggleTimepicker, exclusions: [id] }}
      class="datepicker bg-white dark:bg-gray-800  mt-12 rounded-lg shadow p-4 absolute top-0 right-0 z-50 flex flex-row space-x-4"
      style="width: 8rem"
      transition:fade={{ duration: 150 }}>
      <div class="flex flex-col space-y-1">
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
          on:click={() => {
            const splitValue = value.split(':');
            value = `${parseInt(splitValue[0]) + 1 <= 23 ? parseInt(splitValue[0]) + 1 : '0'}:${splitValue[1]}:00`;
          }}>
          <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <input
          bind:value={hours}
          type="button"
          readonly
          on:change={(val) => (value = `${parseInt(hours)}:${value.split(':')[1]}:00`)}
          class="form-input dark:bg-gray-800 block w-full text-left sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
          on:click={() => {
            const splitValue = value.split(':');
            value = `${parseInt(splitValue[0]) - 1 >= 0 ? parseInt(splitValue[0]) - 1 : '23'}:${splitValue[1]}:00`;
          }}>
          <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      <div class="flex flex-col space-y-1">
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
          on:click={() => {
            const splitValue = value.split(':');
            value = `${splitValue[0]}:${parseInt(splitValue[1]) + 1 <= 59 ? parseInt(splitValue[1]) + 1 : '0'}:00`;
          }}>
          <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <input
          bind:value={minutes}
          type="button"
          readonly
          on:change={(val) => (value = `${value.split(':')[0]}:${parseInt(minutes)}:00`)}
          class="form-input dark:bg-gray-800 block w-full text-left sm:text-sm sm:leading-5 transition ease-in-out duration-150" />
        <button
          type="button"
          class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-200 p-1 rounded-full"
          on:click={() => {
            const splitValue = value.split(':');
            value = `${splitValue[0]}:${parseInt(splitValue[1]) - 1 >= 0 ? parseInt(splitValue[1]) - 1 : '59'}:00`;
          }}>
          <svg class="h-6 w-6 text-secondary-500 inline-flex transform rotate-90 mx-auto my-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  {/if}
</div>
