import { slideOverOpts, modalOpts } from "../../stores";
import { get } from "svelte/store";
import { stringSepToNorm } from "../../random";
import { mutateClient } from "../../GraphQL/mutate";
import { getQueryVariableNames } from "../../GraphQL/helpers";
const tableActions = {
    route: (action) => {
        var _a;
        return ({
            label: (_a = action["label"]) !== null && _a !== void 0 ? _a : "Route",
            needSelectedRow: false,
            roles: action["roles"],
            action: async (tableName, selectedRow, callback) => {
                window.location.hash = action["route"];
                callback();
            },
        });
    },
    routeById: (action) => {
        var _a;
        return ({
            label: (_a = action["label"]) !== null && _a !== void 0 ? _a : "Route By Id",
            needSelectedRow: true,
            roles: action["roles"],
            action: async (tableName, selectedRow, callback) => {
                window.location.hash = action["route"].replace("$id", selectedRow.id);
                callback();
            },
        });
    },
    create: (action) => {
        var _a;
        return ({
            label: (_a = action["label"]) !== null && _a !== void 0 ? _a : "New",
            needSelectedRow: false,
            roles: action["roles"],
            action: async (tableName, selectedRow, callback) => {
                if (get(slideOverOpts)) {
                    slideOverOpts.set(null);
                }
                else {
                    slideOverOpts.set({
                        action: "new",
                        tableName,
                        selectedRow,
                        mutation: action["mutation"],
                        mutationVarMappings: action["mutationVarMappings"],
                        fields: action["fields"],
                        exclusions: [tableName + "-buttoncreate"],
                        actionCallback: callback,
                    });
                }
            },
        });
    },
    edit: (action) => {
        var _a;
        return ({
            label: (_a = action["label"]) !== null && _a !== void 0 ? _a : "Edit",
            needSelectedRow: true,
            roles: action["roles"],
            action: async (tableName, selectedRow, callback) => {
                if (get(slideOverOpts)) {
                    slideOverOpts.set(null);
                }
                else {
                    slideOverOpts.set({
                        action: "edit",
                        tableName,
                        selectedRow,
                        mutation: action["mutation"],
                        mutationVarMappings: action["mutationVarMappings"],
                        query: action["query"],
                        fields: action["fields"],
                        exclusions: [tableName + "-buttonedit"],
                        actionCallback: callback,
                    });
                }
            },
        });
    },
    delete: (action) => {
        var _a;
        return ({
            label: (_a = action["label"]) !== null && _a !== void 0 ? _a : "Delete",
            needSelectedRow: true,
            roles: action["roles"],
            action: async (tableName, selectedRow, callback) => {
                if (get(modalOpts)) {
                    modalOpts.set(null);
                }
                else {
                    modalOpts.set({
                        title: `Delete item in ${stringSepToNorm(tableName, "_")}`,
                        description: "Are you sure you want to delete this item? It will be permanently removed from our servers forever. This action cannot be undone.",
                        color: "red",
                        icon: "warn",
                        exclusions: [tableName + "-buttondelete"],
                        action: {
                            name: "Delete",
                            callback: async () => {
                                const variables = getVariablesSelectedRow(getQueryVariableNames(action["mutation"]), selectedRow);
                                await mutateClient({
                                    mutation: action["mutation"],
                                    variables,
                                });
                                callback();
                            },
                        },
                    });
                }
            },
        });
    },
};
export default tableActions;
//Same exact thing as in SlideoverPanel
const getVariablesSelectedRow = (mutationVariableNames, selectedRow) => {
    let variablesVals = {};
    mutationVariableNames.forEach((varName) => {
        const splitVarName = varName.split("_");
        if (splitVarName[0] == "selectedRow") {
            let valueInContext;
            splitVarName.forEach((varPart, index) => {
                if (index != 0) {
                    valueInContext = valueInContext
                        ? valueInContext[varPart]
                        : selectedRow[varPart];
                }
            });
            variablesVals[varName] = valueInContext;
        }
    });
    return variablesVals;
};
