<script>
  import FormField from "./Form/FormField.svelte";
  import { flattenData, stringSepToNorm } from "../random";
  import { queryClient } from "@src/GraphQL/query";

  export let value = {};
  export let fields = [];
  export let fieldsQuery = null;

  $: if (value == null) value = {};
</script>

{#if fieldsQuery}
  {#await queryClient(fieldsQuery).then(({ data }) => flattenData(data).data) then data }}
    {#each data.fields as field}
      <div class="space-y-1">
        <label for="slideinput-{field.field}" class="block text-sm font-medium leading-5 text-secondary-900 dark:text-gray-200">
          {field.label ?? stringSepToNorm(field.field, '_')}
        </label>
        <div class="relative rounded-md shadow-sm">
          <FormField bind:value={value[field.field]} fieldData={{ ...field, width: 'full' }} hideLabel />
        </div>
      </div>
    {/each}
  {/await}
  {#each fields as field}
    <div class="space-y-1">
      <label for="slideinput-{field.field}" class="block text-sm font-medium leading-5 text-secondary-900 dark:text-gray-200">
        {field.label ?? stringSepToNorm(field.field, '_')}
      </label>
      <div class="relative rounded-md shadow-sm">
        <FormField bind:value={value[field.field]} fieldData={{ ...field, width: 'full' }} hideLabel />
      </div>
    </div>
  {/each}
{:else}
  {#each fields as field}
    <div class="space-y-1">
      <label for="slideinput-{field.field}" class="block text-sm font-medium leading-5 text-secondary-900 dark:text-gray-200">
        {field.label ?? stringSepToNorm(field.field, '_')}
      </label>
      <div class="relative rounded-md shadow-sm">
        <FormField bind:value={value[field.field]} fieldData={{ ...field, width: 'full' }} hideLabel />
      </div>
    </div>
  {/each}
{/if}