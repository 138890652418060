<script lang="ts">
  import GeneralHeading from "../General/GeneralHeading.svelte";
  import FormFieldGroup from "./FormFieldGroup.svelte";
  import { mutateClient } from "@src/GraphQL/mutate";
  import { modalOpts } from "@src/stores";

  export let options;

  const tabs: Array<string> = options["steps"].map((step) => step.label);

  let fieldValues = {};
  let currentTab = 0;

  const confirmSave = () => {
    modalOpts.set({
      title: `Submit?`,
      description: `Are you sure you want to submit this form?`,
      color: "blue",
      icon: "warn",
      exclusions: ["buttonconfirmsave", "buttonconfirmsavespanspan", "buttonconfirmsavespan", "buttonconfirmsavediv", "buttonconfirmsavedivdiv"],
      action: {
        name: "Submit",
        callback: saveData,
      },
    });
  };

  const saveData = async () => {
    const variables = {
      ...Object.fromEntries(Object.entries(fieldValues).map(([field, value]) => [`param_${field}`, value])),
    };

    await mutateClient({
      mutation: options.mutation,
      variables,
    });

    window.location.hash = options.onSubmitRoute;
  };
</script>

<style>
  .taboption {
    @apply w-full flex items-center px-3 py-2 text-sm leading-5 font-medium rounded-md transition ease-in-out duration-150;
  }

  .selected {
    @apply text-secondary-900 bg-secondary-100;
  }

  .selected:hover {
    @apply bg-secondary-100;
  }

  .notselected {
    @apply text-secondary-600;
  }

  .notselected:hover {
    @apply text-secondary-900 bg-secondary-50;
  }
</style>

<div class="mx-auto">
  <div class="pb-8 sm:px-0">
    <div class="flex md:flex-row-reverse flex-wrap">
      <div class="w-full md:w-1/4 md:pl-6 lg:pl-8 md:mb-0 mb-6">
        <div class="bg-white dark:bg-gray-800  shadow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5">
            <nav class="flex flex-col space-y-1">
              {#each tabs as label, index}
                <button
                  on:click={() => (currentTab = index)}
                  class:selected={index == currentTab}
                  class:notselected={index != currentTab}
                  class:group={index != currentTab}
                  class="taboption focus:outline-none font-medium truncate">
                  <span class="flex items-start">
                    <span class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                      {#if index < currentTab}
                        <svg
                          class="h-full w-full text-primary-600 group-hover:text-primary-800"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true">
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd" />
                        </svg>
                      {:else if index == currentTab}
                        <span class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <span class="absolute h-4 w-4 rounded-full bg-primary-200" />
                          <span class="relative block w-2 h-2 bg-primary-600 rounded-full" />
                        </span>
                      {:else}
                        <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <div class="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                        </div>
                      {/if}
                    </span>
                    <span class="ml-3 text-sm font-medium {index == currentTab ? 'text-primary-600' : 'text-gray-500 group-hover:text-gray-900'}">{label}</span>
                  </span>
                </button>
              {/each}
              <button
                id="buttonconfirmsave"
                on:click={confirmSave}
                class="taboption focus:outline-none font-medium truncate bg-primary-600 hover:bg-primary-500 shadow ">
                <span class="flex items-start" id="buttonconfirmsavespanspan">
                  <span class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                    <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" id="buttonconfirmsavedivdiv" aria-hidden="true">
                      <div class="h-2 w-2" id="buttonconfirmsavediv"/>
                    </div>
                  </span>
                  <span class="ml-3 text-sm font-medium text-white" id="buttonconfirmsavespan">Submit</span>
                </span>
              </button>
            </nav>
          </div>
        </div>
      </div>
      <div class="w-full md:w-3/4 md:pr-8 flex flex-col">
        <div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-visible rounded-lg border border-gray-400 dark:border-gray-900">
          <GeneralHeading title={options['steps'][currentTab]['label']}>
            {#if currentTab != tabs.length - 1}<button type="button" class="actionButton" on:click={() => currentTab++}> Next </button>{/if}
            {#if currentTab != 0}<button type="button" class="actionButton" on:click={() => currentTab--}> Previous </button>{/if}
          </GeneralHeading>
          <div class="bg-white dark:bg-gray-800  px-4 py-3 rounded-b-lg sm:px-6">
            <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
              <div class="ml-4 mt-2 w-full flex flex-col space-y-2">
                {#key currentTab}
                  <FormFieldGroup fields={options['steps'][currentTab]['fields']} bind:value={fieldValues} />
                {/key}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
