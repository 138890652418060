import App from "./App.svelte";
/* Start: Dark Mode Handling */
function updateTheme() {
    if (localStorage.theme === "dark" || (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
        document.documentElement.classList.add("dark");
    }
    else {
        document.documentElement.classList.remove("dark");
    }
}
window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", updateTheme);
updateTheme();
/* End: Dark Mode Handling */
//Wait till load of stylesheet
window.addEventListener("load", (event) => {
    document.body.innerHTML = "";
    const app = new App({
        target: document.body,
    });
});
