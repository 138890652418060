<script type="ts">
  export let title: string;
  export let scale = "md";
</script>

<div class="bg-white dark:bg-gray-800  p-3 border-b border-gray-200 dark:border-gray-700 rounded-t-lg">
  <div class="flex items-center justify-between flex-wrap sm:flex-no-wrap">
    <div>
      <h3 class="text-{scale} leading-5 text-gray-500 dark:text-gray-200 font-bold uppercase">{title}</h3>
    </div>
    <div class="flex-shrink-0">
      <span class="inline-flex rounded-md space-x-2">
        <slot/>
      </span>
    </div>
  </div>
</div>