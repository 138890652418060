import gql from "graphql-tag";
import { pad } from "@src/Components/Form/helpers";
import { systemContext, routeContext } from "../stores";
import { get } from "svelte/store";
import { firstValueFrom, rx } from "../rxfsm";
import auth from "../auth";
export const getQueryFirstDef = (query) => {
    return (typeof query == "string" ? gql(query) : query)["definitions"][0];
};
export const getSourceSelections = (source) => {
    return source["selectionSet"]["selections"];
};
export const getQueryFirstSelection = (query) => {
    return getSourceSelections(getQueryFirstDef(query))[0];
};
export const getQueryFirstSelectionName = (query) => {
    return getQueryFirstSelection(query)["name"]["value"];
};
export const getQueryVariableNames = (query) => {
    return getQueryFirstDef(query)["variableDefinitions"].map((def) => def.variable.name.value);
};
export const getQueryVariableType = (query, varName) => {
    var _a, _b, _c, _d, _e, _f;
    const found = getQueryFirstDef(query)["variableDefinitions"].find((def) => def.variable.name.value == varName);
    return (_d = (_c = (_b = (_a = found === null || found === void 0 ? void 0 : found.type) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : (_f = (_e = found === null || found === void 0 ? void 0 : found.type) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.value;
};
export const getQueryFieldNames = (query) => {
    return getQueryFirstDef(query);
};
export const addQueryVariables = (query, newVariableTypes) => {
    let newQuery = JSON.parse(JSON.stringify(query));
    Object.keys(newVariableTypes).forEach((varName) => {
        newQuery = addQueryVariable(newQuery, varName, newVariableTypes[varName]);
    });
    return newQuery;
};
export const addQueryVariable = (query, name, type) => {
    let newQuery = JSON.parse(JSON.stringify(query));
    newQuery["definitions"][0]["selectionSet"]["selections"][0]["arguments"].push({
        kind: "Argument",
        name: { kind: "Name", value: name },
        value: { kind: "Variable", name: { kind: "Name", value: name } },
    });
    newQuery["definitions"][0]["variableDefinitions"].push({
        kind: "VariableDefinition",
        variable: { kind: "Variable", name: { kind: "Name", value: name } },
        type: { kind: "NamedType", name: { kind: "Name", value: type } },
        directives: [],
    });
    return newQuery;
};
export const getVariablesFromContext = async (mutationVariableNames) => {
    const authClaims = await firstValueFrom(auth.claims.pipe(rx.filter(val => val != null)));
    const contextValues = {
        ...get(systemContext),
        ...get(routeContext),
        date: {
            now: (() => {
                const now = new Date();
                now.setMilliseconds(0);
                return now.toISOString().replace(".000Z", "") + "+00:00";
            })(),
            today: (() => {
                const now = new Date();
                const year = now.getFullYear();
                const month = now.getMonth() + 1;
                const day = now.getDate();
                return `${year}-${pad(month, 2)}-${pad(day, 2)}`;
            })(),
        },
        user: {
            id: authClaims["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
        },
    };
    let variablesVals = {};
    mutationVariableNames.forEach((varName) => {
        const splitVarName = varName.split("_");
        if (splitVarName[0] == "context") {
            let valueInContext;
            splitVarName.forEach((varPart, index) => {
                if (index != 0) {
                    valueInContext = valueInContext
                        ? valueInContext[varPart]
                        : contextValues[varPart];
                }
            });
            variablesVals[varName] = valueInContext;
        }
    });
    return variablesVals;
};
//Functional Programming
export const mapMutationVariables = (unmappedVariables, mutationVarNames, mutationVarMappings = {}) => {
    const mappedVariables = Object.fromEntries(mutationVarNames.map((mutationVarName) => {
        const unmappedValue = unmappedVariables[mutationVarName];
        if (mutationVarMappings[mutationVarName]) {
            return mapMutationVar(unmappedVariables, [
                mutationVarName,
                mutationVarMappings[mutationVarName],
            ]);
        }
        return [mutationVarName, unmappedValue];
    }));
    return mappedVariables;
};
//Functional Programming
export const mapMutationVar = (unmappedVariables, [mappingKey, { input, output, inputObjType, inputMapKeyPattern }]) => {
    const replaceVar = input.replace("$", "");
    const mappingValueReplaced = (() => {
        var _a, _b;
        switch (inputObjType) {
            case "array":
                return ((_b = (_a = unmappedVariables[replaceVar]) === null || _a === void 0 ? void 0 : _a.map((inputValue) => {
                    const strOutputWithInputVal = JSON.stringify(output[0]).replace(new RegExp(inputMapKeyPattern, "g"), inputValue);
                    const strOutputWithContexts = Object.keys(unmappedVariables).reduce((prev, cur) => {
                        return prev.replace(new RegExp(`\\\$${cur}`, "g"), unmappedVariables[cur]);
                    }, strOutputWithInputVal);
                    return JSON.parse(strOutputWithContexts);
                })) !== null && _b !== void 0 ? _b : []);
        }
    })();
    console.log({
        unmappedVariables,
        replaceVar,
        mappingKey,
        mappingValueReplaced,
    });
    return [mappingKey, mappingValueReplaced];
};
