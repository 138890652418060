import { state, transition, final, nested, invoke, immediate, reduce, guard, action, rx, passObsToProm } from "../rxfsm";
import { routeHashObs, routeStore, routeMatch } from "../stores";
import { get } from "svelte/store";

import auth from "../auth";

const loggedOutMachine = (getParentContextObs) => nested("loggedin", {
  checking: state(
    immediate(
      "accountmanagement",
      guard(() => routeMatch("/account-management", get(routeHashObs), true)),
    ),
    immediate("signinform", action(() => routeStore.update(val => ({ ...val, fragment: "#" })))),
  ),
  //Sign in stuff
  signinform: state(
    transition(
      "signin",
      "signingin",
      reduce((ctx, ev) => ({ ...ctx, signinform: ev.data, error: undefined })),
    ),
    transition(
      "toggleforgotpasswordform",
      "sendforgotpasswordform",
      reduce((ctx, ev) => ({ ...ctx, error: undefined })),
    ),
  ),
  signingin: invoke(
    () => passObsToProm(getParentContextObs().pipe(rx.skip(1), rx.pluck("signinform")), auth.loginWithEmailPassword),
    transition(
      "done",
      "done",
      reduce((ctx, ev) => ({ ...ctx, signinform: undefined, error: undefined })),
    ),
    transition(
      "error",
      "signinform",
      reduce((ctx, ev) => ({ ...ctx, error: ev.error })),
    ),
  ),
  //Forgot password stuff
  sendforgotpasswordform: state(
    transition(
      "sendforgotpassword",
      "sendingforgotpassword",
      reduce((ctx, ev) => ({ ...ctx, sendforgotpasswordform: ev.data, error: undefined })),
    ),
    transition(
      "toggleforgotpasswordform",
      "signinform",
      reduce((ctx, ev) => ({ ...ctx, error: undefined })),
    ),
  ),
  sendingforgotpassword: invoke(
    () => passObsToProm(getParentContextObs().pipe(rx.skip(1), rx.pluck("sendforgotpasswordform")), auth.sendPasswordResetEmail),
    transition(
      "done",
      "didforgotpasswordform",
      reduce((ctx, ev) => ({ ...ctx, sendforgotpasswordform: undefined, error: undefined })),
    ),
    transition(
      "error",
      "sendforgotpasswordform",
      reduce((ctx, ev) => ({ ...ctx, error: ev.error })),
    ),
  ),
  didforgotpasswordform: state(transition("toggleforgotpasswordform", "signinform")),
  //Password reset stuff
  accountmanagement: state(
    transition(
      "sendresetpassword",
      "handlingresetpassword",
      reduce((ctx, ev) => ({ ...ctx, accountmanagement: ev.data, error: undefined })),
    ),
  ),
  handlingresetpassword: invoke(
    () => passObsToProm(getParentContextObs().pipe(rx.skip(1), rx.pluck("accountmanagement")), auth.handleResetPassword),
    transition(
      "done",
      "signingin",
      reduce((ctx, ev) => ({ ...ctx, signinform: ev.data, error: undefined })),
    ),
    transition(
      "error",
      "accountmanagement",
      reduce((ctx, ev) => ({ ...ctx, error: ev.error })),
    ),
  ),
  done: final(),
});

export default loggedOutMachine;