export default function interceptFetch({ augmentRequest }) {
  const { fetch: origFetch } = window;

  window.fetch = async (...args) => {
    const newArgs = await augmentRequest(args);

    const response = await origFetch(...newArgs);

    return response;
  };
}