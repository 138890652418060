import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, split } from "apollo-link";
import ApolloClient from "apollo-client";
export let client;
export function initClient() {
    const HASURA_URL = globalThis.__ENV__.__HASURA_URL__;
    console.log(HASURA_URL);
    const headers = {
        "content-type": "application/json",
    };
    const cache = new InMemoryCache();
    const wsLink = new WebSocketLink({
        uri: "wss://" + HASURA_URL,
        options: {
            reconnect: true,
            lazy: true,
            connectionParams: () => {
                return { headers };
            },
        },
        webSocketImpl: WebSocket,
    });
    const httpLink = new HttpLink({
        uri: "https://" + HASURA_URL,
        headers,
    });
    const splitLink = split(({ query }) => {
        const mainDef = getMainDefinition(query);
        return mainDef.kind === "OperationDefinition" && mainDef.operation === "subscription";
    }, wsLink, httpLink);
    const link = ApolloLink.from([splitLink]);
    client = new ApolloClient({
        link,
        cache,
    });
}
