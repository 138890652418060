import { writable, merge } from "svelte-pipeable-store";
import { get } from "svelte/store";

import { createRouteStore, match } from "svelte-store-router";

import { rx } from "./rxfsm";

const localStorageWritable = (key, initialValue) => {
  // create an underlying store
  const store = writable(initialValue);
  const { subscribe, set } = store;
  // get the last value from localStorage
  const json = localStorage.getItem(key);

  // use the value from localStorage if it exists
  if (json) {
    set(JSON.parse(json));
  }

  // return an object with the same interface as svelte's writable()
  return {
    // capture set and write to localStorage
    set(value) {
      localStorage.setItem(key, JSON.stringify(value));
      set(value);
    },
    // capture updates and write to localStore
    update(cb) {
      const value = cb(get(store));
      this.set(value);
    },
    // punt subscriptions to underlying store
    subscribe,
  };
};

const routeStore = createRouteStore({ fragmentParse: false });
const routeHashObs = merge(routeStore).pipe(rx.map(storeVal => storeVal.fragment.substring(1).split("?")[0]));
const routeMatch = (path, routeHash, loose = false) => {
  return match(path, "/" + routeHash, loose);
};

const systemContext = writable({}); //System ID and stuff
const systemExtendedContext = writable({}); //System UI, system theme, server url, functions url, files url, etc.
const routeContext = writable({});
const notificationOpts = writable(null);
const slideOverOpts = writable(null);
const modalOpts = writable(null);

export { routeMatch, routeHashObs, routeStore, systemContext, systemExtendedContext, routeContext, notificationOpts, slideOverOpts, modalOpts };
