<script lang="ts">
  import QrCode from "svelte-qrcode"
  export let id: string, value: string, required: boolean, disabled: boolean;
  export let isUnsavedUpdates = false;
  export let roundRightonFocus = true;
</script>
	
<div class="flex flex-row">
	{#if value}
	  <QrCode value={value} />
	{/if}
</div>