import { getQueryVariableNames, getVariablesFromContext, mapMutationVariables } from "./helpers";
import { client } from "./client";
import { print } from "graphql";
import gql from "graphql-tag";
class GQLMutationError extends Error {
    constructor(...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GQLMutationError);
        }
        this.name = 'GQLMutationError';
    }
}
const mutateClient = async ({ mutation, variables = {}, mutationVarMappings = {}, }) => {
    const finalMutation = typeof mutation == "string" ? gql(mutation) : mutation;
    const mutationVarNames = getQueryVariableNames(mutation);
    const unMappedVariables = { ...variables, ...(await getVariablesFromContext(mutationVarNames)) };
    const mappedVariables = mapMutationVariables(unMappedVariables, mutationVarNames, mutationVarMappings);
    console.log(unMappedVariables, mappedVariables);
    console.log({
        mutation: print(finalMutation),
        variables: mappedVariables,
    });
    return client.mutate({ mutation: finalMutation, variables: mappedVariables }).then(({ data }) => {
        client.cache.reset(); //TODO: Not just this lmfao
        return { data };
    }).catch(error => {
        const gqlErrors = error.graphQLErrors;
        if (gqlErrors && gqlErrors.length != 0) {
            switch (gqlErrors[0].extensions.code) {
                case "validation-failed":
                    throw new GQLMutationError("A field was invalid");
                case "permission-error":
                    throw new GQLMutationError("Action not permitted");
            }
            if (gqlErrors[0].message == "database query error") {
                const dbErrorMsg = gqlErrors[0].extensions.internal.error.message;
                if (!dbErrorMsg)
                    throw error;
                throw new GQLMutationError(dbErrorMsg);
            }
        }
        throw error;
    });
};
export { mutateClient };
